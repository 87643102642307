/*********************
PAGINATION
*********************/

.pagination{
    font-family: $ff-salesforce-sans;
    background: #f5f2f2;
    display: inline-block;
    color: $grey-darker;
    padding: .5rem 1rem;
    border-radius: 50px;
    font-size: .9rem;
}
