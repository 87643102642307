/*********************
LINKS
*********************/
a{
    color: $blue;
    text-decoration: underline;
    &:hover{
        color: $blue-dark;
        text-decoration: none;
    }
    &:focus,
    &:active{
        color: $blue;
        outline: none;
    }

}
