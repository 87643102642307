@import '../../styles/variables';
@import '../../styles/mixin';

.name {
  &-form {
    margin-top: 50px;

    input {
      width: 100%;
      max-width: 530px;
      height: auto;
      margin: 0 auto;
      padding: 0;
      font: {
        family: $ff-trailhead;
        size: 46px;
        weight: $fw-bold;
      }
      line-height: normal;
      text-align: center;
      background-color: transparent !important;
      border: 0 !important;
      border-bottom: $c-green-vogue solid 1px !important;
      box-shadow: none !important;
      color: $c-lochmara;

      @include respond-to('xxlarge') {
        font-size: 56px;
      }
    }

    button {
      width: 100%;
      max-width: 260px;
      margin-top: 40px;
      padding: 15px;
      font: {
        size: 16px;
        weight: $fw-bold;
      }
      line-height: 1.375;
      text-transform: uppercase;
      background-color: $c-lochmara;
      border: 0;
      border-radius: 4px;
      color: $c-white;
      cursor: pointer;

      &:hover {
        background-color: $c-green-vogue;
      }

      &:disabled {
        opacity: 0.25;
        cursor: not-allowed;
      }

      @include respond-to('xxlarge') {
        margin-top: 60px;
      }
    }
  }

}
