// Fonts
@font-face {
  font-family: 'Salesforce Sans';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/SalesforceSans/SalesforceSans-Regular.woff2') format('woff2'), url('/fonts/SalesforceSans/SalesforceSans-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Salesforce Sans';
  font-weight: 300;
  font-style: normal;
  src: url('/fonts/SalesforceSans/SalesforceSans-Light.woff2') format('woff2'), url('/fonts/SalesforceSans/SalesforceSans-Light.woff') format('woff');
}
@font-face {
  font-family: 'Salesforce Sans';
  font-weight: 600;
  font-style: normal;
  src: url('/fonts/SalesforceSans/SalesforceSans-SemiBold.woff2') format('woff2'), url('/fonts/SalesforceSans/SalesforceSans-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Salesforce Sans';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/SalesforceSans/SalesforceSans-Bold.woff2') format('woff2'), url('/fonts/SalesforceSans/SalesforceSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Avant Garde';
  font-weight: 500;
  font-style: normal;
  src: url('/fonts/AvantGarde/AvantGarde-Medium.woff2') format('woff2'), url('/fonts/AvantGarde/AvantGarde-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Avant Garde';
  font-weight: 600;
  font-style: normal;
  src: url('/fonts/AvantGarde/AvantGarde-Demi.woff2') format('woff2'), url('/fonts/AvantGarde/AvantGarde-Demi.woff') format('woff');
}
@font-face {
  font-family: 'Avant Garde';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/AvantGarde/AvantGarde-Bold.woff2') format('woff2'), url('/fonts/AvantGarde/AvantGarde-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Trailhead';
  font-weight: 500;
  font-style: normal;
  src: url('/fonts/Trailhead/Trailhead-Medium.woff2') format('woff2'), url('/fonts/Trailhead/Trailhead-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Trailhead';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/Trailhead/Trailhead-Bold.woff2') format('woff2'), url('/fonts/Trailhead/Trailhead-Bold.woff') format('woff');
}
