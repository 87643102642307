.loading-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  background-color: #5fbce0;
  text-align: center;
  height: 100vh;
  font-family: "lato";
  font-weight: 100;

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .circle {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: #6dc5e7;
    border-radius: 50%;
    animation: loading 1.5s ease-in-out infinite;
    transform-origin: bottom center;
    position: relative;
    margin: 0.2rem;
  }
  @keyframes loading {
    0% {
      transform: translateY(0px);
      background-color: #00a1e0;
    }
    50% {
      transform: translateY(50px);
      background-color: #fff;
    }
    100% {
      transform: translateY(0px);
      background-color: #00a1e0;
    }
  }
  .circle-1 {
    animation-delay: 0.1s;
  }
  .circle-2 {
    animation-delay: 0.2s;
  }
  .circle-3 {
    animation-delay: 0.3s;
  }
  .circle-4 {
    animation-delay: 0.4s;
  }
  .circle-5 {
    animation-delay: 0.5s;
  }
  .circle-6 {
    animation-delay: 0.6s;
  }
  .circle-7 {
    animation-delay: 0.7s;
  }
  .circle-8 {
    animation-delay: 0.8s;
  }
}

.loading-icon {
  position: absolute;
  bottom: 40%;
  bottom: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  font-family: "lato";
  font-weight: 100;
  width: 500px;
  left: 50%;
  transform: translateX(-50%);

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: rgba(0, 161, 224, 1);
    border-radius: 50%;
    animation: my-loading 1.5s ease-in-out infinite;
    transform-origin: bottom center;
    position: relative;
    margin: 0.1rem;
  }
  @keyframes my-loading {
    0% {
      height: 10px;
      width: 10px;
      transform: translateY(0px);
      background-color: rgb(114, 191, 221);
    }
    50% {
      height: 5px;
      width: 5px;
      transform: translateY(50px);
      background-color: rgba(114, 191, 224, 0);
    }
    100% {
      height: 10px;
      width: 10px;
      transform: translateY(0px);
      background-color: rgba(114, 191, 224, 1);
    }
  }
  .circle-1 {
    animation-delay: 0.1s;
  }
  .circle-2 {
    animation-delay: 0.2s;
  }
  .circle-3 {
    animation-delay: 0.3s;
  }
  .circle-4 {
    animation-delay: 0.4s;
  }
  .circle-5 {
    animation-delay: 0.5s;
  }
  .circle-6 {
    animation-delay: 0.6s;
  }
  .circle-7 {
    animation-delay: 0.7s;
  }
  .circle-8 {
    animation-delay: 0.8s;
  }
}
