/*********************
LISTS
*********************/

//List Star
.list-star{
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
        margin-left: 30px;
        margin-bottom: 5px;
        position: relative;
        &:before {
            content: '';
            background-image: url('/images/star-bullet.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 90%;
            width: 20px;
            height: 20px;
            display: block;
            position: absolute;
            left: -30px;
            top: 5px;
        }
        &:nth-child(odd){
            &:before {
                transform: scaleX(-1);
            }
        }
    }
}
