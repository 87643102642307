@import '../../styles/variables';
@import '../../styles/mixin';
#community-page{
  position: relative;
  overflow: hidden;
}
.section-container{
  z-index: 9;
}
.material {
  &-card {
    &--container {
      margin-top: 60px;
    }

    &--item {
      max-width: 400px;
      min-height: 360px;
      margin: {
        top: 30px;
        right: auto;
        left: auto;
      }
      padding: 40px 30px;
      background-color: $c-white;
      border-radius: 20px;
      box-shadow: 5px 15px 25px rgba($c-black, 0.1);

      @include respond-to('medium') {
        width: calc(100% - 30px);
      }
    }

    &--image {
      max-width: 260px;
      margin: {
        right: auto;
        bottom: 20px;
        left: auto;
      }
      background-color: $c-gallery;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &--title {
      margin: 0;
      font: {
        size: 22px;
        weight: $fw-bold;
      }
      line-height: 1.5;
    }

    &--info {
      margin-top: 10px;
      font-size: 18px;
      line-height: 28px;
    }

    &--button {
      margin-top: 30px;

      a {
        display: block;
        max-width: 260px;
        margin: {
          right: auto;
          left: auto;
        }
        padding: 15px;
        font: {
          size: 16px;
          weight: $fw-bold;
        }
        line-height: 1.375;
        text: {
          decoration: none;
          transform: uppercase;
        }
        background-color: $c-lochmara;
        border: 0;
        border-radius: 4px;
        color: $c-white;
        cursor: pointer;
  
        &:hover {
          background-color: $c-green-vogue;
        }
      }
    }
  }
}
.cloud1, .cloud2, .cloud3{
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
}

.cloud1{
  background-image: url(/images/community_page/cloud1.png);
  width: 550px;
  height: 144px;
  top: 15%;
  animation: cloud1Animation 260s infinite;
}

.cloud2{
  background-image: url(/images/community_page/cloud2.png);
  width: 600px;
  height: 100px;
  top: 10%;
  animation: cloud2Animation 220s infinite;
}

.cloud3{
  z-index: 0;
  background-image: url(/images/community_page/cloud3.png);
  background-position: center bottom;
  width: 1920px;
  height: 208px;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  @include respond-to('xlarge') {
      width: 100%;
      background-repeat: repeat-x;
  }
  animation: cloud3Animation 500s infinite;
}

@keyframes cloud1Animation {
  0%{
      left: -100px;
  }
  100% {
      left: calc(100%);
  }
}

@keyframes cloud2Animation {
  0%{
      left: calc(100% - 400px) ;
  }
  100% {
      left: -600px;
  }
}

@keyframes cloud3Animation {
  0% {
      transform: translate(-60%, 0);
  }
  100% {
      transform: translate(0, 0);
  }
}

.bird1, .bird2{
  background-repeat: no-repeat;
  position: absolute;
}

.bird1{
  background-image: url(/images/community_page/bird1.svg);
  width: 87px;
  height: 76px;
  right: 20%;
  top: 55%;
  @include respond-to('medium') {
      top: 20%;
  }
}

.bird2{
  background-image: url(/images/community_page/bird2.svg);
  width: 89px;
  height: 100px;
  left: 20%;
  top: 80%;
}
