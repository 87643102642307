/*********************
CARDS
*********************/

.card{
    color: $c-green-vogue;
    border-color: $blue-dark;
    background-color: $white;
    border-radius: 25px;
    margin: 10px;
    box-shadow: 0 15px 15px rgba($black, .2);
    @include transition(.25s linear all);
    &:hover{
        box-shadow: 0 0 10px rgba($black, .2);
        transform: scale(1.01);
    }
    .card__inner{
        padding: 40px;
        @include breakpoint(large){
            padding: 40px 60px;
        }
    }

    .card__image{
        width: 170px;
        height: 170px;
        background: $cyan;
        border-radius: 100%;
        margin: 0 auto 20px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        &.icon-view{
            background-image: url('/images/SF-STARs-myTrailhead-Shield.svg');
        }
        &.icon-trophy{
            background-image: url('/images/SF-STARs-myTrailhead-Trophy.svg');
        }
        &.icon-heart{
            background-image: url('/images/SF-STARs-myTrailhead-Heart.svg');
        }
    }

    .card__header{
        flex: 1 0 auto;

    }

    .button{
        margin-bottom: 0;
    }

    &.card__resources{
        border: 3px solid #fff;
        z-index: 1;
        .card__image{
            width: 100%;
            padding-bottom: 56.5%;
            height: 0;
            border-radius: 0;
            background-size: cover;
            margin: 0;
        }
        .card__inner{
            padding: 20px;
        }

        .card__subheader{
            font-family: $ff-salesforce-sans;
            min-height: 19px;
        }

    }
}
