@import '../../styles/variables';
@import '../../styles/mixin';

.learn-buttons{
  text-align: center;
  margin: 0 auto;
  .learn-button{
    margin: 1rem;
  }
}
.trail-title{
  color: #fff;
    text-align: center;
    margin-bottom: 30px;
}
.trails {
    padding-top: 5vw;
  &-button {
    margin-top: 40px;

    ul,
    li {
      margin: 0;
      padding: 0;
    }

    ul {
      list-style: none;
    }

    li + li {
      margin-top: 25px;
    }

    a {
      display: block;
      padding: 15px;
      max-width: 250px;
      margin: {
        right: auto;
        left: auto;
      }
      padding: 15px;
      font: {
        size: 16px;
        weight: $fw-bold;
      }
      line-height: 1.25;
      text: {
        decoration: none;
        transform: uppercase;
      }
      border: 0;
      border-radius: 4px;
      color: $c-white;
      cursor: pointer;
      transition: background-color 0.25s ease-out;

      &.i1 {
        background-color: $c-corn;

        &:hover {
          background-color: $c-green-vogue;
        }
      }

      &.i2 {
        background-color: $c-lochmara;

        &:hover {
          background-color: $c-green-vogue;
        }
      }
    }
  }

  &-card {
    &--container {
      margin: {
        top: 50px;
        right: -15px;
        left: -15px;
      }

      @include respond-to('xlarge') {
        max-width: 1640px;
        margin: {
          right: auto;
          left: auto;
        }
      }
    }

    &--top,
    &--bottom {
      padding: 10px;
      font: {
        size: 1.5em;
        weight: $fw-bold;
      }
      letter-spacing: 0.1em;
      line-height: 1;
      text-transform: uppercase;
      background-color: $c-lochmara!important;
      color: $c-white;
    }

    &--top {
      border-radius: 20px 20px 0 0;
    }

    &--bottom {
      border-radius: 0 0 20px 20px;
    }

    &--middle {
      padding: 30px 15px;
      min-height: 150px;
      transition: height 0.5s ease-in;
      background: {
        image: url(/images/06_trails/trails-map.png);
        color: $blue-light;
        repeat: repeat;
      }

      @include respond-to('medium') {
        text-align: left;
      }

      @include respond-to('xlarge') {
        padding: 50px 15px;
      }
    }

    &--block {
      max-width: 1080px;
      margin: {
        right: auto;
        left: auto;
      }
      padding: 15px 30px;
      background-color: $c-white;
      border-radius: 20px;
      box-shadow: 5px 15px 25px rgba($c-black, 0.1);

      @include respond-to('medium') {
        padding: 20px 40px;
      }
    }
    &--block + &--block {
      margin-top: 30px;

      @include respond-to('medium') {
        margin-top: 50px;
      }
    }

    &--item {
      padding: 15px 0;

      @include respond-to('medium') {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 800px;
        margin: {
          right: auto;
          left: auto;
        }
      }
    }

    &--title,
    &--subtitle {
      color: $c-green-vogue;
      margin: 0;
    }

    &--title {
      &:hover, &:focus{
        text-decoration: underline;
      }
      font: {
        size: 20px;
        weight: $fw-bold;
      }
      line-height: 1.2;

      @include respond-to('medium') {
        max-width: 800px;
        margin: {
          right: auto;
          left: auto;
        }
      }

      @include respond-to('xlarge') {
        // font-size: 30px;
      }
    }

    &--subtitle {
      font: {
        family: $ff-salesforce-sans;
        size: 16px;
        weight: $fw-bold;
      }
      line-height: 1.5;

      a {
        text-decoration: underline;
        color: $c-green-vogue;
        transition: color 0.25s ease-out;

        &:hover {
          color: $c-lochmara;
        }
      }

      @include respond-to('medium') {
        flex: 1 1 auto;
      }
  
      @include respond-to('xxlarge') {
        font-size: 18px;
      }
    }

    &--image {
      width: 100px;
      margin: 0 auto 15px auto;

      img {
        width: 100%;
        height: auto;
      }

      @include respond-to('medium') {
        flex: 0 0 auto;
        margin: {
          bottom: 0;
          right: 35px;
        }
      }
    }

    &--info {
      text-align: center;
      margin-top: 15px;
      font-size: 0.9rem;
      color: $blue;
      line-height: 1.5;

      @include respond-to('medium') {
        flex: 0 0 auto;
        min-width: 140px;
        margin: {
          top: 0;
          left: 35px;
        }
        text-align: right;
      }

      @include respond-to('xxlarge') {
        font-size: 1rem;
      }
    }
  }
}
