.background-image-top,
.background-image-bottom{
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  @include respond-to('xlarge'){
      background-size: contain;
  }
}
.background-image-top{
  top: 0;
  background-position: top center;
}
.background-image-bottom{
  bottom: 0;
  background-position: bottom center;
}