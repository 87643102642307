@import "../../styles/variables";
@import "../../styles/mixin";

.mainlogo {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
}
.bea{
  font-weight: 900;
  font-size: 1.4em;
  transform: translateY(10px);
}
.company-logo {
  height: 50px;
  margin-top: 20px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.dates {
  font-size: 0.85rem;
  margin-top: 5px;
  font-weight: 700;
}
.hashtag {
  font-weight: 700;
  margin-top: 10px;
}
.hero-heading-img {
  max-width: 380px;
}
.hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  background-color: transparent; //#fff;
  z-index: 2;
  &-heading {
    padding: 0;
    @include respond-to("medium") {
      padding: 0 120px;
    }
  }
  &-button {
    margin-top: 20px;
    margin-bottom: 140px;

    button {
      width: 100%;
      max-width: 250px;
      padding: 15px;
      font: {
        size: 16px;
        weight: $fw-bold;
      }
      line-height: 1.25;
      text-transform: uppercase;
      background-color: $c-lochmara;
      border: 0;
      border-radius: 4px;
      color: $c-white;
      cursor: pointer;
      transition: background-color 0.25s ease-out;

      &:hover {
        background-color: $c-green-vogue;
      }
    }
  }

  &-container {
    position: relative;
    z-index: 2;
  }

  &-character,
  &-sign {
    position: absolute;
    bottom: 0;
  }

  &-character {
    z-index: 1;
    min-width: 160px;
    max-width: 280px;
    left: 50%;
    transform: translateX(-50%);
    width: 180px;
    @include respond-to("large") {
      width: 180px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &-sign {
    right: 0;
    z-index: 3;
    width: 30%;
    min-width: 120px;
    max-width: 280px;

    @include respond-to("medium") {
      right: 10%;
      width: 15%;
    }

    img {
      display: block;

      &.back {
        width: 100%;
        height: auto;
      }

      &.front {
        width: auto;
      }
    }

    div {
      position: absolute;
      top: 26%;
      left: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44%;
      height: 24%;
      overflow: hidden;
    }
  }
}
