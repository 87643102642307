/*********************
SOCIAL LINKS
*********************/

.social-links{
    list-style-type: none;
    margin: 40px 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    li{
        margin: 0 5px;
        a{
            width: 50px;
            height: 50px;
            border-radius: 100%;
            display: flex;
            border: 2px solid $blue-dark;
            padding: 10px;
            color: $blue-dark;
            background-color: $cyan;
            @include transition (.25s linear all);
            svg{
                width: 25px;
                height: 25px; 
            }
            &:hover{
                color: $white;
                background: $blue-dark;
            }
        }
    }
}