@import '../../styles/variables';
@import '../../styles/mixin';
.chests-section-subtitle{
  margin-bottom: 50px;
}
.green-bg{
      background: #257838;
}
.grey-bg{
  background: #eaf5fd;
  background-color: #eaf5fd!important;
}
.chests {
  padding-top: 5vw;
  &-card {
    &--container {
      position: relative;
      z-index: 0;
      margin: {
        top: -20vw;
        right: calc(-0.625rem - 15px);
        bottom: -50px;
        left: calc(-0.625rem - 15px);
      }
      background: {
        repeat: repeat-y;
        position: center center;
        size: 100% auto;
      }

      @include respond-to('medium') {
        margin: {
          right: calc(-0.46875rem - 15px);
          left: calc(-0.46875rem - 15px);
        }
      }
      @include respond-to('xlarge') {
        margin: {
          right: -0.46875rem;
          left: -0.46875rem;
        }
      }
    }

    &--list {
      transform: translateY(-110px);
      position: relative;
      z-index: 2;
      //overflow: hidden;
      margin: {
        top: -24vw; //-40px;
        bottom: -20px;
      }

      @include respond-to('medium') {
        margin: {
          //top: -80px;
          top: -22vw;
          bottom: -60px;
        }
      }
      @include respond-to('large') {
        margin: {
          //top: -140px;
          bottom: -100px;
        }
      }
      @include respond-to('xlarge') {
        margin: {
          //top: -180px;
          bottom: -140px;
        }
      }
      @include respond-to('xxxlarge') {
        margin: {
          //top: -240px;
          bottom: -120px;
        }
      }
    }

    &--background {
      position: relative;
      z-index: 1;
      background: {
        color: #257838;
        repeat: no-repeat;
        size: 100% 101%;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        opacity: 0;
      }

      &.top {
        background: {
          color: $c-white;
          position: 0 0px;
        }
      }

      &.bottom {
        background: {
          color: $c-white;
          position: 0 -1px;
        }
      }
    }
  }
}
.opened.green-rim{
  border-top: 8px solid rgb(1, 135, 1);
}
.green-rim{
  border-top: 8px solid rgba(255, 255, 255,0);

}
.mixcard{
  transition: all 0.5s;
  height: calc(100% - 25px);
  cursor: pointer;
  // background-color: #fff;
  padding: 30px;
  margin: 10px;

  min-height: 200px;
  border-radius: 30px;
  // box-shadow: 0 0 15px RGBA(0,0,0,0.1);
  &:hover, &:focus{
    background-color: $blue;
    *{color: #fff;}
  }
  &__title{
    color: $c-green-vogue;
    font-weight: bold;
  }
  &__description{
    color: $c-lochmara;
  }
  &__image__wrap{
    position: relative;
    max-height: 120px;
    height: 120px;
    width: 120px;
    margin: 0 auto;
    border-radius: 50%;
    border-style: solid;
    border-width: 5px;
    &.grey{
      background-color: rgb(228, 228, 228);
      border-color: #cacaca;

    }
    &.yellow{
      background-color: $c-banana-mania;
      border-color: #f0e3b5;

    }
    &.blue{
      background-color: $c-malibu;
      border-color: #9bd6f2;
    }
    &.teal{
      background-color: $c-niagara;
      border-color: #13b9ae;

    }
    &.purple{
      background-color: rgb(138, 138, 227);
      border-color: rgb(168, 168, 232)
    }
    &.pink{
      background-color: rgb(205, 138, 227);
      border-color: rgb(224, 168, 232)
    }
    margin-bottom: 20px;
  }
  &__image{
    width: 70px;
    margin: 0 auto;
    height:108px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &__image__wrap.no-theme{
border: none;
    .mixcard__image{
      width: 120px;
      height: 120px;
    }
  }

}
.checkmark{
  width: 50px;
  height: 50px;
  position: absolute;
  top: -10px;
  right: -10px;
  background-image: url('/images/04_chests/chest-checkmark.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.chest {
  position: relative;
  text-align: center;
  margin-top: -5vw;
  @include respond-to('large') {
    margin-top: -4.5vw;
  }
  @include respond-to('xlarge') {
    margin-top: -4vw;
  }
  @include respond-to('xxlarge') {
    margin-top: -5em;
  }
  &__image {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    padding-bottom: 100%;
    text-align: center;
    background-image: url('/images/04_chests/chests-shadow.png');
    background-repeat: no-repeat;
    background-position: center 100%;
    background-size: 90% auto;

    img,
    svg {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: auto;

      &.open {
        opacity: 1;
      }
    }
  }

  &__clickzone {
    position: absolute;
    bottom: 0;
    left: 23%;
    z-index: 99;
    width: 50%;
    padding-bottom: 50%;
    opacity: 0.5;
    cursor: pointer;
  }

  &__title {
    margin: 0;
    font: {
      size: 22px;
      weight: $fw-bold;
    }
    line-height: 1.2;
    margin-bottom: .5em;
    color: $white;
    padding: 0 .5em;
  }

  &__description {
    font-size: 0.8rem;
    font-weight: 500;
    color: #fff;
    padding: 0 .5em;
  }
  
  &__is_required {
    font-size: 1rem;
    color: #fff;
    font-weight: 700;
    padding-top: 5px;
  }

  a {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: block;
  }

  &__image {
    &.opened {
      svg {
        #evogz7wm7vd6 {
          opacity: 1;
        }
        #evogz7wm7vd4 {
          opacity: 0;
        }
        #evogz7wm7vd7_to {
          transform: translate(828.048346px, 340.347324px);
        }
      }
    }

    &.open {
      svg {
        #evogz7wm7vd2_tr {
          animation: evogz7wm7vd2_tr__tr 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd2_tr__tr {
          0% {
            transform: translate(188.899384px, 384.650566px) rotate(0deg);
          }
          3.200000% {
            transform: translate(188.899384px, 384.650566px) rotate(4.883719deg);
          }
          4% {
            transform: translate(188.899384px, 384.650566px)
              rotate(-3.925094deg);
          }
          6% {
            transform: translate(188.899384px, 384.650566px) rotate(4.883719deg);
          }
          8% {
            transform: translate(188.899384px, 384.650566px)
              rotate(-3.925094deg);
          }
          10% {
            transform: translate(188.899384px, 384.650566px) rotate(0deg);
          }
          100% {
            transform: translate(188.899384px, 384.650566px) rotate(0deg);
          }
        }
        #evogz7wm7vd4 {
          animation: evogz7wm7vd4_c_o 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd4_c_o {
          0% {
            opacity: 1;
          }
          10.600000% {
            opacity: 1;
          }
          10.800000% {
            opacity: 0;
          }
          100% {
            opacity: 0;
          }
        }
        #evogz7wm7vd5 {
          animation: evogz7wm7vd5_c_o 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd5_c_o {
          0% {
            opacity: 0;
          }
          10.600000% {
            opacity: 0;
          }
          10.800000% {
            opacity: 1;
          }
          12% {
            opacity: 1;
          }
          12.200000% {
            opacity: 0;
          }
          100% {
            opacity: 0;
          }
        }
        #evogz7wm7vd6 {
          animation: evogz7wm7vd6_c_o 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd6_c_o {
          0% {
            opacity: 0;
          }
          12% {
            opacity: 0;
          }
          12.200000% {
            opacity: 1;
          }
          100% {
            opacity: 1;
          }
        }
        #evogz7wm7vd7_to {
          animation: evogz7wm7vd7_to__to 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd7_to__to {
          0% {
            transform: translate(827.045587px, 733.282433px);
          }
          13.600000% {
            transform: translate(827.045587px, 733.282433px);
            animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
          }
          18.800000% {
            transform: translate(828.048346px, 340.347324px);
          }
          100% {
            transform: translate(828.048346px, 340.347324px);
          }
        }
        #evogz7wm7vd9_to {
          animation: evogz7wm7vd9_to__to 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd9_to__to {
          0% {
            transform: translate(365.31458px, 242.095496px);
          }
          19% {
            transform: translate(365.31458px, 242.095496px);
          }
          20.400000% {
            transform: translate(300px, 204.400002px);
          }
          26.200000% {
            transform: translate(300px, 204.400002px);
          }
          88.600000% {
            transform: translate(297.485214px, 456.769799px);
          }
          100% {
            transform: translate(297.485214px, 520.826739px);
          }
        }
        #evogz7wm7vd9_ts {
          animation: evogz7wm7vd9_ts__ts 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd9_ts__ts {
          0% {
            transform: scale(0.346854, 0.346854);
          }
          19% {
            transform: scale(0.346854, 0.346854);
          }
          20.400000% {
            transform: scale(1, 1);
          }
          100% {
            transform: scale(1, 1);
          }
        }
        #evogz7wm7vd9 {
          animation: evogz7wm7vd9_c_o 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd9_c_o {
          0% {
            opacity: 0;
          }
          18.800000% {
            opacity: 0;
          }
          19% {
            opacity: 1;
          }
          59.200000% {
            opacity: 1;
          }
          100% {
            opacity: 1;
          }
        }
        #evogz7wm7vd10_to {
          animation: evogz7wm7vd10_to__to 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd10_to__to {
          0% {
            transform: translate(0px, 0px);
          }
          21.400000% {
            transform: translate(0px, 0px);
          }
          24.200000% {
            transform: translate(67.983893px, -56.991697px);
          }
          27.200000% {
            transform: translate(84.805607px, -62.439595px);
          }
          27.400000% {
            transform: translate(84.805607px, -62.438739px);
          }
          36.800000% {
            transform: translate(63.49289px, -10.664002px);
          }
          60.200000% {
            transform: translate(55.041327px, 38.116121px);
          }
          92.200000% {
            transform: translate(84.805609px, 91.063683px);
          }
          100% {
            transform: translate(84.805609px, 91.063683px);
          }
        }
        #evogz7wm7vd12_to {
          animation: evogz7wm7vd12_to__to 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd12_to__to {
          0% {
            transform: translate(0px, 0px);
          }
          21.400000% {
            transform: translate(0px, 0px);
          }
          24.200000% {
            transform: translate(94.342378px, -51.293488px);
          }
          27.200000% {
            transform: translate(113.855506px, -51.293494px);
          }
          27.400000% {
            transform: translate(113.855506px, -51.292689px);
          }
          40.800000% {
            transform: translate(87.838004px, -6.605793px);
          }
          64.200000% {
            transform: translate(70.109651px, 42.174329px);
          }
          92.200000% {
            transform: translate(113.855511px, 93.031892px);
          }
          100% {
            transform: translate(113.855511px, 93.031892px);
          }
        }
        #evogz7wm7vd14_to {
          animation: evogz7wm7vd14_to__to 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd14_to__to {
          0% {
            transform: translate(0px, 0px);
          }
          21.400000% {
            transform: translate(0px, 0px);
          }
          24.200000% {
            transform: translate(52.350001px, -78.667679px);
          }
          27.200000% {
            transform: translate(74.46488px, -83.349974px);
          }
          27.400000% {
            transform: translate(74.464844px, -83.349175px);
          }
          50.800000% {
            transform: translate(106.503439px, -38.678417px);
          }
          68.200000% {
            transform: translate(120.97473px, 7.415359px);
          }
          92.200000% {
            transform: translate(68px, 59.857981px);
          }
          100% {
            transform: translate(68px, 59.857981px);
          }
        }
        #evogz7wm7vd16_to {
          animation: evogz7wm7vd16_to__to 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd16_to__to {
          0% {
            transform: translate(0px, 0px);
          }
          21.400000% {
            transform: translate(0px, 0px);
          }
          24.200000% {
            transform: translate(29.23px, -72.881896px);
          }
          27.200000% {
            transform: translate(52.645754px, -83.065387px);
          }
          27.400000% {
            transform: translate(52.645754px, -83.064759px);
          }
          38.800000% {
            transform: translate(31.725557px, -52.448417px);
          }
          66.200000% {
            transform: translate(73.192434px, -9.338866px);
          }
          92.200000% {
            transform: translate(52.645754px, 29.58798px);
          }
          100% {
            transform: translate(52.645754px, 29.58798px);
          }
        }
        #evogz7wm7vd18_to {
          animation: evogz7wm7vd18_to__to 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd18_to__to {
          0% {
            transform: translate(0px, 0px);
          }
          21.400000% {
            transform: translate(0px, 0px);
          }
          24.200000% {
            transform: translate(52.756723px, -28.619998px);
          }
          27.200000% {
            transform: translate(72.643439px, -33.823499px);
          }
          27.400000% {
            transform: translate(72.643439px, -33.82266px);
          }
          42.800000% {
            transform: translate(94.108004px, 16.262684px);
          }
          62.200000% {
            transform: translate(42.879158px, 65.835359px);
          }
          92.200000% {
            transform: translate(72.643441px, 116.584079px);
          }
          100% {
            transform: translate(72.643441px, 116.584079px);
          }
        }
        #evogz7wm7vd20_to {
          animation: evogz7wm7vd20_to__to 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd20_to__to {
          0% {
            transform: translate(0px, 0px);
          }
          21.400000% {
            transform: translate(0px, 0px);
          }
          24.200000% {
            transform: translate(9.070338px, -46.963487px);
          }
          27.200000% {
            transform: translate(27.282592px, -61.372679px);
          }
          27.400000% {
            transform: translate(27.282563px, -61.372207px);
          }
          40.800000% {
            transform: translate(7.352659px, -34.756858px);
          }
          64.200000% {
            transform: translate(42.132445px, -5.467346px);
          }
          92.200000% {
            transform: translate(22.079091px, 23.184212px);
          }
          100% {
            transform: translate(22.079091px, 23.184212px);
          }
        }
        #evogz7wm7vd22_to {
          animation: evogz7wm7vd22_to__to 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd22_to__to {
          0% {
            transform: translate(0px, 0px);
          }
          21.400000% {
            transform: translate(0px, 0px);
          }
          24.200000% {
            transform: translate(37.560001px, -41.513487px);
          }
          27.200000% {
            transform: translate(67.48014px, -54.433487px);
          }
          27.400000% {
            transform: translate(67.480132px, -54.433103px);
          }
          48.800000% {
            transform: translate(96.97085px, -27.783919px);
          }
          66.200000% {
            transform: translate(55.070804px, -9.637346px);
          }
          92.200000% {
            transform: translate(66.179265px, 14.314213px);
          }
          100% {
            transform: translate(66.179265px, 14.314213px);
          }
        }
        #evogz7wm7vd24_to {
          animation: evogz7wm7vd24_to__to 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd24_to__to {
          0% {
            transform: translate(0px, 0px);
          }
          21.400000% {
            transform: translate(0px, 0px);
          }
          24.200000% {
            transform: translate(80.432381px, -18.1px);
          }
          27.200000% {
            transform: translate(99.945513px, -23.725417px);
          }
          27.400000% {
            transform: translate(99.945492px, -23.72512px);
          }
          42.800000% {
            transform: translate(71.818011px, -8.890008px);
          }
          64.200000% {
            transform: translate(42.904783px, 10.275603px);
          }
          92.200000% {
            transform: translate(96.175518px, 29.607981px);
          }
          100% {
            transform: translate(96.175518px, 29.607981px);
          }
        }
        #evogz7wm7vd26_to {
          animation: evogz7wm7vd26_to__to 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd26_to__to {
          0% {
            transform: translate(0px, 0px);
          }
          21.400000% {
            transform: translate(0px, 0px);
          }
          24.200000% {
            transform: translate(9.136271px, -85.796838px);
          }
          27.200000% {
            transform: translate(3.93277px, -109.212592px);
          }
          27.400000% {
            transform: translate(3.93277px, -109.212141px);
          }
          48.800000% {
            transform: translate(23.116249px, -81.260702px);
          }
          70% {
            transform: translate(5.668783px, -56.654814px);
          }
          92% {
            transform: translate(-1.211624px, -32.371469px);
          }
          100% {
            transform: translate(-1.211624px, -32.371469px);
          }
        }
        #evogz7wm7vd28_to {
          animation: evogz7wm7vd28_to__to 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd28_to__to {
          0% {
            transform: translate(0px, 0px);
          }
          21.400000% {
            transform: translate(0px, 0px);
          }
          24.200000% {
            transform: translate(-47.589688px, -14.82px);
          }
          27.200000% {
            transform: translate(-73.607193px, -23.725417px);
          }
          27.400000% {
            transform: translate(-73.607221px, -23.724876px);
          }
          44.800000% {
            transform: translate(-47.217948px, 13.342684px);
          }
          66.200000% {
            transform: translate(-41.103543px, 39.890114px);
          }
          92.200000% {
            transform: translate(-78.587193px, 73.291892px);
          }
          100% {
            transform: translate(-78.587193px, 73.291892px);
          }
        }
        #evogz7wm7vd30_to {
          animation: evogz7wm7vd30_to__to 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd30_to__to {
          0% {
            transform: translate(0px, 0px);
          }
          21.400000% {
            transform: translate(0px, 0px);
          }
          24.200000% {
            transform: translate(-2.57px, -81.047679px);
          }
          27.200000% {
            transform: translate(-26.120751px, -95.633475px);
          }
          27.400000% {
            transform: translate(-26.120719px, -95.633047px);
          }
          50.800000% {
            transform: translate(-0.059443px, -69.135784px);
          }
          64.200000% {
            transform: translate(-27.832962px, -44.195769px);
          }
          92.200000% {
            transform: translate(-20.463379px, -18.881836px);
          }
          100% {
            transform: translate(-20.463379px, -18.881836px);
          }
        }
        #evogz7wm7vd32_to {
          animation: evogz7wm7vd32_to__to 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd32_to__to {
          0% {
            transform: translate(0px, 0px);
          }
          21.400000% {
            transform: translate(0px, 0px);
          }
          24.200000% {
            transform: translate(10.378371px, -70.297679px);
          }
          27.200000% {
            transform: translate(-3.931256px, -81.559974px);
          }
          27.400000% {
            transform: translate(-3.931241px, -81.559614px);
          }
          44.800000% {
            transform: translate(11.237212px, -60.265784px);
          }
          70.200000% {
            transform: translate(-24.57167px, -37.670769px);
          }
          92.200000% {
            transform: translate(-1.329506px, -17.051836px);
          }
          100% {
            transform: translate(-1.329506px, -17.051836px);
          }
        }
        #evogz7wm7vd34_to {
          animation: evogz7wm7vd34_to__to 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd34_to__to {
          0% {
            transform: translate(0px, 0px);
          }
          21.400000% {
            transform: translate(0px, 0px);
          }
          24.200000% {
            transform: translate(-24.435px, -65.053487px);
          }
          27.200000% {
            transform: translate(-43.077137px, -70.203494px);
          }
          27.400000% {
            transform: translate(-43.077171px, -70.20279px);
          }
          48.800000% {
            transform: translate(-23.599442px, -27.737316px);
          }
          66.200000% {
            transform: translate(-64.603543px, 13.195603px);
          }
          92.200000% {
            transform: translate(-49.207127px, 56.114584px);
          }
          100% {
            transform: translate(-49.207127px, 56.114584px);
          }
        }
        #evogz7wm7vd36_to {
          animation: evogz7wm7vd36_to__to 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd36_to__to {
          0% {
            transform: translate(0px, 0px);
          }
          21.400000% {
            transform: translate(0px, 0px);
          }
          24.200000% {
            transform: translate(-19.445875px, -54.553487px);
          }
          27.200000% {
            transform: translate(-45.46338px, -61.123487px);
          }
          27.400000% {
            transform: translate(-45.46338px, -61.123024px);
          }
          42.800000% {
            transform: translate(-15.543248px, -33.817318px);
          }
          70.200000% {
            transform: translate(-59.502961px, -6.364641px);
          }
          92.200000% {
            transform: translate(-45.463379px, 21.919998px);
          }
          100% {
            transform: translate(-45.463379px, 21.919998px);
          }
        }
        #evogz7wm7vd38_to {
          animation: evogz7wm7vd38_to__to 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd38_to__to {
          0% {
            transform: translate(0px, 1px);
          }
          21.400000% {
            transform: translate(0px, 1px);
          }
          24.200000% {
            transform: translate(-76.937137px, -69.742679px);
          }
          27.200000% {
            transform: translate(-108.269688px, -72.575386px);
          }
          27.400000% {
            transform: translate(-108.26971px, -72.574849px);
          }
          46.800000% {
            transform: translate(-143.561393px, -40.781857px);
          }
          64.200000% {
            transform: translate(-96.803544px, -9.147345px);
          }
          92.200000% {
            transform: translate(-112.172313px, 23.739213px);
          }
          100% {
            transform: translate(-112.172313px, 23.739213px);
          }
        }
        #evogz7wm7vd40_to {
          animation: evogz7wm7vd40_to__to 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd40_to__to {
          0% {
            transform: translate(0px, 0px);
          }
          21.400000% {
            transform: translate(0px, 0px);
          }
          24.200000% {
            transform: translate(-65.729688px, -57.863487px);
          }
          27.200000% {
            transform: translate(-93.407193px, -56.673494px);
          }
          27.400000% {
            transform: translate(-93.407265px, -56.67288px);
          }
          48.800000% {
            transform: translate(-67.017948px, -21.29392px);
          }
          66.200000% {
            transform: translate(-108.895565px, 18.405359px);
          }
          92.200000% {
            transform: translate(-106.415945px, 53.304583px);
          }
          100% {
            transform: translate(-106.415945px, 53.304583px);
          }
        }
        #evogz7wm7vd42_to {
          animation: evogz7wm7vd42_to__to 5000ms linear 1 normal forwards;
        }
        @keyframes evogz7wm7vd42_to__to {
          0% {
            transform: translate(0px, 0px);
          }
          21.400000% {
            transform: translate(0px, 0px);
          }
          24.200000% {
            transform: translate(-35.024875px, -101.246322px);
          }
          27.200000% {
            transform: translate(-59.797011px, -106.449816px);
          }
          27.400000% {
            transform: translate(-59.79705px, -106.44914px);
          }
          42.800000% {
            transform: translate(-37.254316px, -65.710501px);
          }
          64.200000% {
            transform: translate(-19.352836px, -25.153687px);
          }
          92.200000% {
            transform: translate(-66.814128px, 14.687034px);
          }
          100% {
            transform: translate(-66.814128px, 14.687034px);
          }
        }
      }
    }
  }
}
