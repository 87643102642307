@import '../../styles/variables';
@import '../../styles/mixin';

.team {
  padding-bottom: 0!important;
  background-color: rgb(180, 221, 255);
  &-card {
    &--container {
      margin-top: 20px;
    }

    &--item {
      max-width: 500px;
      margin: {
        top: 40px;
        right: auto;
        left: auto;
      }
      padding: 30px;
      background-color: $c-white;
      border-radius: 20px;
      box-shadow: 0 10px 20px rgba($c-black, 0.15);

      @include respond-to('medium') {
        width: calc(100% - 30px);
        min-height: calc(100% - 40px);
      }
    }

    &--image {
      width: 160px;
      height: 160px;
      margin: {
        right: auto;
        bottom: 20px;
        left: auto;
      }
      overflow: hidden;
      background: {
        color: $c-gallery;
        position: center bottom;
        repeat: no-repeat;
        size: cover;
      }
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }

    &--title {
      margin: 0;
      font: {
        size: 20px;
        weight: $fw-bold;
      }
      line-height: 1.5;
    }

    &--info {
      margin-top: 10px;
      line-height: 1.5;
    }

    &--button {
      margin-top: 20px;

      a {
        display: block;
        max-width: 250px;
        margin: {
          right: auto;
          left: auto;
        }
        padding: 15px;
        font: {
          size: 16px;
          weight: $fw-bold;
        }
        line-height: 1.25;
        text: {
          decoration: none;
          transform: uppercase;
        }
        background-color: $blue-light;
        border: 0;
        border-radius: 4px;
        color: $c-white;
        cursor: pointer;
        transition: background-color 0.25s ease-out;
  
        &:hover {
          background-color: $c-green-vogue;
        }
      }
    }
  }
}
