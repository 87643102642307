.legal-footer-wrap{
	position: absolute;
	bottom: 0px;
	z-index: 0;
	text-align: center;
	width: 100%;
	font-size: 0.7rem;
	ul{
	font-size: 0.7rem;
		text-align: center;
		margin: 0 auto;

		li{list-style-type: none; display: inline-block; padding: 5px;}
	}
}
.legal-footer{
	background-color: RGBA(255,255,255,0.8);
	display: inline-block;
	padding: 10px 10px 0px 10px;
}
.consent{
	transition: all 0.5s ease-out;
	display: inline-block;
	padding: 10px;
	margin: 10px;
	background-color: #ddd;
}