/*********************
TOKEN
*********************/

.token{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    max-width: 160px;
    border-radius: 10px;
    @include breakpoint(medium only){
        transform: translate(-90%, -50%);
    }

    //padding: 0 10px;
    .token__image{
        width: 30px;
        height: 30px;
        background-image: url('/images/coin-flip4.svg');
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 5px;
        @include breakpoint(large){
            width: 50px;
            height: 50px;
        }

    }
    .token__credit{
        font-weight: bold;
        text-shadow: 0 .5px 0 rgba($white, .8);
        margin-bottom: 0;
        font-size: rem-calc(16);
        @include breakpoint(large){
            font-size: rem-calc(18);
        }
    }

}
