@import '../../styles/variables';
@import '../../styles/mixin';
.prizes-description{
  font-size: 14px;
}
.footnote{font-size: 0.7rem;}
.price{font-weight: 700;
margin-top: 10px;
}
.rewards {
  background-color: rgb(180, 221, 255);
  &-prize {
    &--container {
      margin-top: 20px;
      position: relative;
      z-index: 2;
    }

    &--item {
      max-width: 500px;
      margin: {
        top: 40px;
        right: auto;
        left: auto;
      }
      padding: 30px;
      background-color: $c-white;
      border: {
        radius: 20px;
        top-style: solid;
        top-width: 15px;
      }
      box-shadow: 0 10px 20px rgba($c-black, 0.15);

      @include respond-to('medium') {
        width: calc(100% - 30px);
        min-height: calc(100% - 40px);
      }

      &.i1 {
        border-top-color: $c-malibu;
      }

      &.i2 {
        border-top-color: $c-lightning-yellow;
      }

      &.i3 {
        border-top-color: $c-carnation-pink;
      }
    }
    
    &--title {
      margin: 0;
      font: {
        // family: $ff-trailhead;
        size: 24px;
        weight: $fw-bold;
      }
      // line-height: 1.25;
      color: $c-lochmara;

      @include respond-to('xxlarge') {
        font-size: 32px;
      }
    }
    &--subtitle{
      font-weight: 500;
      margin-bottom: 20px;
      font-size: 13px;
    }
    &--info {
      line-height: 1.5;

      span {
        display: block;
      }

      strong {
        font-weight: $fw-bold;
      }

      &.i1 {
        margin-top: 15px;
        font-size: 18px;
      }

      &.i2 {
        margin-top: 20px;
        font-size: 16px;
      }

      &.i3 {
        margin-top: 10px;
        font-size: 12px;
      }
      ul,
      li {
        margin: 0;
        padding: 0;
      }

      ul {
        list-style: none;
      }
      li{font-size: 15px;}
    }
  }

  &-card {
    &--container {
      margin-top: 30px;
    position: relative;
      z-index: 9;
    }

    &--item {
      margin-top: 30px;
      padding: 0 15px;
    }

    &--image {
      img {
        max-width: 75%;
        height: auto;
      }
    }

    &--title {
      margin: 0;
      font: {
        size: 20px;
        weight: $fw-bold;
      }
      line-height: 1.5;
    }

    &--info {
      margin-top: 10px;
      line-height: 1.5;

      ul,
      li {
        margin: 0;
        padding: 0;
      }

      ul {
        list-style: none;
      }

      span {
        font-weight: $fw-bold;
      }

      strong {
        font-weight: $fw-regular;
      }
    }
  }
  .bird {
    position: absolute;
    top: 100px;
    right: 0;
    width: 150px;
    height: 150px;
  
    @include respond-to('medium') {
      top: 100px;
      right: 10%;
    }
    @include respond-to('large') {
      right: 20%;
    }
  }
}