@import '../../styles/variables';
@import '../../styles/mixin';

#sf_plus-page {
  .card{
    height: 100%;
  }
  &.darkbg{
  background-color: #044390;
  }
  background-size: cover;
  background-repeat: repeat-x;
  
  &-card {
    &--container {
      margin-top: 60px;
    }

    &--item {
      max-width: 400px;
      min-height: 360px;
      margin: {
        top: 30px;
        right: auto;
        left: auto;
      }
      padding: 40px 30px;
      background-color: $c-white;
      border-radius: 20px;
      box-shadow: 5px 15px 25px rgba($c-black, 0.1);

      @include respond-to('medium') {
        width: calc(100% - 30px);
      }
    }

    &--image {
      max-width: 260px;
      margin: {
        right: auto;
        bottom: 20px;
        left: auto;
      }
      background-color: $c-gallery;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &--title {
      margin: 0;
      font: {
        size: 22px;
        weight: $fw-bold;
      }
      line-height: 1.5;
    }

    &--info {
      margin-top: 10px;
      font-size: 18px;
      line-height: 28px;
    }

    &--button {
      margin-top: 30px;

      a {
        display: block;
        max-width: 260px;
        margin: {
          right: auto;
          left: auto;
        }
        padding: 15px;
        font: {
          size: 16px;
          weight: $fw-bold;
        }
        line-height: 1.375;
        text: {
          decoration: none;
          transform: uppercase;
        }
        background-color: $c-lochmara;
        border: 0;
        border-radius: 4px;
        color: $c-white;
        cursor: pointer;
  
        &:hover {
          background-color: $c-green-vogue;
        }
      }
    }
  }
  .small-baloon {
    position: absolute;
    top: 20%;
    left: 50px;
    width: 50px;
    height: 50px;
  
    @include respond-to('medium') {
    }
    @include respond-to('large') {
    }
  }
  .large-baloon {
    position: absolute;
    bottom: 0%;
    right: 0;
    width: 150px;
    height: 150px;
  
    @include respond-to('medium') {
      top: 20%;
      right: 10%;
    }
    @include respond-to('large') {
      right: 20%;
    }
  }
}
.card.card__resources{
  position: relative;
  text-decoration: none;
  .card__inner{
    .card-header{
      text-decoration: none;
    }
    margin-bottom: 50px;
  }
  .button{
    position: absolute;
    left:50%;
    transform: translateX(-50%);
    bottom: 20px;
    min-width: 175px;
    white-space: nowrap;
  }
}

.video-container{
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
