@import '../../styles/variables';
@import '../../styles/mixin';

.final-text{
  margin-top: 30px;
}
.congrats {
  margin-top: 30px;
  &-button {
    margin-top: 40px;

    ul,
    li {
      margin: 0;
      padding: 0;
    }

    ul {
      list-style: none;
    }

    li + li {
      margin-top: 25px;
    }

    a {
      display: inline-block;
      min-width: 270px;
      padding: 15px;
      font: {
        size: 16px;
        weight: $fw-bold;
      }
      line-height: 1.375;
      text: {
        decoration: none;
        transform: uppercase;
      }
      border: 0;
      border-radius: 4px;
      color: $c-white;
      cursor: pointer;

      &.i1 {
        background-color: $c-corn;

        &:hover {
          background-color: $c-green-vogue;
        }
      }

      &.i2 {
        background-color: $c-lochmara;

        &:hover {
          background-color: $c-green-vogue;
        }
      }

      &.i3 {
        background-color: $c-niagara;

        &:hover {
          background-color: $c-green-vogue;
        }
      }

      @include respond-to('medium') {
        min-width: 300px;
      }
    }
  }
}